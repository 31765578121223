import { MouseEvent } from 'react';

import { ButtonAnchor } from '@components/button/ButtonAnchor';

import { formatPriceWithoutCurrency } from '@common/utils/price';
import {
	createEventSlug,
	getEventType,
	getIsEventSoldOut,
	getRepresentativeTicketPrice,
	getTicketUrl,
} from '@common/utils/programme';
import {
	applyUTMParams,
	DiceLinkoutOriginPageTypes,
} from '@common/utils/tracking';
import { DiceEvent, ProgrammeLabels } from '@interfaces';

import styles from './listing-anchors.module.scss';

interface ListingAnchorProps extends ProgrammeLabels {
	event: DiceEvent;
	isOffSale: boolean;
	isElsewherePresents?: boolean;
	diceAccessCode?: string;
	transparent?: boolean;
	// eslint-disable-next-line react/no-unused-prop-types
	diceLinkoutOriginPageType?: DiceLinkoutOriginPageTypes;
}

export const ListingAnchors = ({
	event,
	isElsewherePresents = false,
	labelTicket,
	labelTicketOffSale,
	labelTicketWaitlist,
	isOffSale = false,
	diceAccessCode,
	transparent,
	diceLinkoutOriginPageType,
}: ListingAnchorProps) => {
	const { name, currency, saleStartDate } = event;
	const isEventSoldOut = getIsEventSoldOut(event);
	const ticketPrice = getRepresentativeTicketPrice(event);
	const eventType = getEventType(event.typeTags);
	const ticketUrl = getTicketUrl(event, diceAccessCode);

	if (isOffSale) {
		return (
			<button className="button button--full-width" disabled type="button">
				{labelTicketOffSale}
			</button>
		);
	}

	const trackClarityEvent = () => {
		if (window.clarity && !isEventSoldOut) {
			window.clarity('event', 'buyTicket');
		}
	};

	const onTicketButtonClick = (
		e: MouseEvent<HTMLAnchorElement>,
		shouldTrackClarityEvent?: boolean,
	) => {
		e.preventDefault();

		if (shouldTrackClarityEvent) {
			trackClarityEvent();
		}

		if (ticketUrl) {
			const newTicketUrl = applyUTMParams({
				url: ticketUrl,
				sessionStorage,
				urlSearchParams: new URLSearchParams(window.location.search),
				originPageType: diceLinkoutOriginPageType,
				pagePath: `/events/${createEventSlug(event)}`,
			});

			if (!newTicketUrl) return;

			window.location.href = newTicketUrl;
		}
	};

	const renderButtonAnchors = () => {
		if (ticketUrl) {
			return (
				<ButtonAnchor
					aria-label={
						isEventSoldOut
							? `${labelTicketWaitlist}: ${name}`
							: `${labelTicket}: ${name}`
					}
					className={
						transparent
							? styles[`button__ticket--is-transparent`]
							: styles[`button__ticket--is-${eventType.toLowerCase()}`]
					}
					href={ticketUrl}
					isElsewherePresents={isElsewherePresents}
					itemProp="url"
					onClick={(e) => onTicketButtonClick(e, true)}
				>
					{isEventSoldOut ? <>{labelTicketWaitlist}</> : labelTicket}
				</ButtonAnchor>
			);
		}

		return null;
	};

	return (
		<div itemProp="offers" itemScope itemType="https://schema.org/Offer">
			<div className="stack-sm">{renderButtonAnchors()}</div>
			<link
				href={`https://schema.org/${isEventSoldOut ? `SoldOut` : `InStock`}`}
				itemProp="availability"
			/>
			{ticketPrice ? (
				<meta
					content={formatPriceWithoutCurrency({ cents: ticketPrice })}
					itemProp="price"
				/>
			) : null}
			<meta content={currency} itemProp="priceCurrency" />
			<meta content={saleStartDate} itemProp="validFrom" />
		</div>
	);
};
